import React from "react";

import "./App.css";
import "./About.js";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* Topper */}
      <h2></h2>
      <Router>
        <Link to="/" className="Small-Link Yard-Font">
          Yard Designs{" "}
        </Link>
        {/*<Link to="/about" className="Small-Link">About  </Link> */}
        <Link to="/quote" className="Small-Link">
          Quote{" "}
        </Link>
        <Link to="/example" className="Small-Link">
          Example{" "}
        </Link>

        {/* Header Bar */}
        <header className="App-header">
          <h2 className="Empty-Row"></h2>
          <h1 className="TransBackGround">
            Affordable Online Yard Design Expert.
          </h1>
          <p className="TransBackGround">
            YardDesign provides the best service to create your dream yard
            Remotely!
          </p>
          <p className="TransBackGround">
            Our affordable price starts from <strong>$199</strong>. Each project
            takes <strong>2 to 4 weeks.</strong>
          </p>

          <Link to="/quote" className="link link-outer ">
            Free and Instant Quote
          </Link>
        </header>

        <Routes>
          <Route exact path="/" element={<Main />}></Route>
        </Routes>

        <Routes>
          <Route exact path="/about" element={<About />}></Route>
        </Routes>
        <Routes>
          <Route exact path="/quote" element={<Quote />}></Route>
        </Routes>
        <Routes>
          <Route exact path="/example" element={<Examples />}></Route>
        </Routes>
      </Router>

      {/* Introduction */}

      <div></div>
    </div>
  );
}

function Main1() {
  return (
    <div className="body-part">
      <div>
        <p>
          YardDesign as the platform garentees your project is delivered with
          quality and in time! Our team is here to design your dream yards.
          <br></br>
          What we are professional at? Hardscaping - Decking - Patios - Pools -
          Spa - Play Space - Outdoor kitchens.
          <br></br>
          What you get?
          <br></br>
          Plan palette & CAD plans/blueprint & 3D Renderings
          <br></br>
          Want to learn more?
          <br></br>
          Check Examples and Videos about how YardDesign works!
        </p>
      </div>

      <div>
        <div class="column"> This is C1</div>
        <div class="column"> This is C2</div>
      </div>

      <div>
        <Examples />
      </div>
    </div>
  );
}

function Main() {
  return (
    <div className="body-part">
      <h2>
        YardDesign garentees your yard design project is delivered with quality
        and in time!
      </h2>

      <ul class="card-wrapper">
        <li class="card">
          <h3>Professional Experts</h3>
          <ul class="bullet">
            <li class="bullet">
              {" "}
              Full time designers with proven experiences.
            </li>
            <li class="bullet">
              {" "}
              Team is assigned to design your dream yard with high quality in
              time.
            </li>
          </ul>
        </li>
        <li class="card">
          <h3>Outstanding Service</h3>
          <ul class="bullet">
            <li class="bullet"> Hardscaping, Lighting, Decking</li>
            <li class="bullet"> Pools, Spa, Play space, Outdoor kitchens</li>
            <li class="bullet"> Plants</li>
            <li class="bullet"> 24/7 service</li>
            <li class="bullet"> We response within 24 hours of your request</li>
          </ul>
        </li>
        <li class="card">
          <h3>Beautiful Designs</h3>
          <ul class="bullet">
            <li class="bullet">
              {" "}
              CAD plans/blueprint - use it to communicate and quote the price
              with local landscaping contractors.
            </li>
            <li class="bullet">
              {" "}
              3D Renderings - you see how the exact the look before
              consturctions to make sure this is what your need.
            </li>
            <li class="bullet">
              {" "}
              Project construction fee estimations with details list.
            </li>
          </ul>
        </li>
      </ul>

      <br />

      <h2>How it works?</h2>
      <ul class="card-wrapper">
        <li class="card">
          <img
            src="https://images.unsplash.com/photo-1611916656173-875e4277bea6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400"
            alt=""
          />
          <h3>
            <a href="">Week 1 - Information Gathering</a>
          </h3>
          <ul class="bullet">
            <li class="bullet">
              {" "}
              Submit your yard pictures and video. Answer the questionare.
            </li>
            <li class="bullet"> Express your ideal.</li>
            <li class="bullet"> Designn team is assigned.</li>
            <li class="bullet">
              {" "}
              Team reviews your data and get back to you for reqrquirement
              clarification
            </li>
          </ul>
        </li>
        <li class="card">
          <img
            src="https://images.unsplash.com/photo-1611083360739-bdad6e0eb1fa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400"
            alt=""
          />
          <h3>
            <a href="">Week 2 - First Draft</a>
          </h3>
          <ul class="bullet">
            <li class="bullet"> First version of CAD and 3D Renderings. </li>
          </ul>
          <h3>
            <a href="">Week 3 & 4 - Revisions</a>
          </h3>
          <ul class="bullet">
            <li class="bullet"> Revisions and iterators. </li>
            <li class="bullet">
              A small revision takes 2 to 4 business days.{" "}
            </li>
            <li class="bullet">A big revision takes 3 to 5 business days. </li>
          </ul>
        </li>
        <li class="card">
          <img
            src="https://images.unsplash.com/photo-1613230485186-2e7e0fca1253?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400"
            alt=""
          />
          <h3>
            <a href="">After Completing Design...</a>
          </h3>
          <ul class="bullet">
            <li class="bullet"> Collect and adress your feedback. </li>
            <li class="bullet">
              {" "}
              Provides tips and strategy to find realiable local contractors.{" "}
            </li>
            <li class="bullet"> Materials Purchase suggestions</li>
          </ul>
        </li>
      </ul>

      <br />
      <br />
      <br />

      <div class="footer-clean card-wrapper">
        <footer>
          <div class="">
            <div class="">
              <ul class="card-wrapper">
                <ul class="bullet">
                  <h3 className="Small-Link Yard-Font">Yard Designs</h3>
                  <a href="/">Home</a>
                  <li> About us </li>
                  <li> blogs </li>
                </ul>

                <ul class="bullet">
                  <h3>Contact</h3>
                  <li> support@yard-designs.com </li>
                </ul>
                <ul class="bullet">
                  <h3>Links</h3>
                  <li> blogs </li>
                  <li> pricing </li>
                </ul>
              </ul>

              <div class="col-lg-3 item social">
                <p class="copyright">YardDesigns © 2022</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

function About() {
  return <div className="About"> about</div>;
}

function Quote() {
  function sendEmail(event) {
    event.preventDefault();

    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let country = document.getElementById("country").value;
    let zipcode = document.getElementById("zipcode").value;
    let yardtype = document.querySelector("#yardtype").value;
    let yardsize = document.querySelector("#yardsize").value;

    let finalmessage = `Name : ${name} <br>  Email : ${email} <br>  Country : ${country} <br> Zipcode : ${zipcode} <br> yardtype : ${yardtype} <br> yardsize : ${yardsize}`;
    let finalSubject = "Quote from Email : ${email}";

    window.Email.send({
      SecureToken : "8aa493af-11de-4774-973d-04e6a45e77ea",
      To: "yyjcynthiaamazon@gmail.com",
      From: "yyjcynthiaamazon@gmail.com",
      Subject: finalSubject,
      Body: finalmessage
    }).then(
      alert(
        "Thanks for contacting us. The platform is in Beta phase not released yet. We are providing free yard design for our beta customers to collect feedbacks. We will email you the detail about this beta program. If you are intersted please reply to it"
      )
    );
  }

  return (
    <div className="App-Left">
      <p>p</p>
      <p></p>
      <p></p>
      <p></p>

      <div class="container">
        <form onSubmit={sendEmail}>
          <p></p>
          <p></p>
          <div class="row">
            <div class="col-25">
              <label for="fname"> Name:</label>
            </div>
            <div class="col-75">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name.."
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="email"> Email:</label>
            </div>
            <div class="col-75">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Your email.."
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="country"> Country:</label>
            </div>
            <div class="col-75">
              <input
                type="text"
                id="country"
                name="country"
                placeholder="Your Country.."
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="zip"> Zip Code:</label>
            </div>
            <div class="col-75">
              <input
                type="text"
                id="zipcode"
                name="zipcode"
                placeholder="Your Zip Code.."
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="projects"> Project Includs:</label>
            </div>
            <div class="col-75">
              <select id="yardtype">
                <option value="backyard">Back Yard</option>
                <option value="frontyard">Front Yard</option>
                <option selected value="both">
                  Back Yard and Front Yard
                </option>
                <option value="others">Others</option>
              </select>
            </div>

            <div class="row">
              <div class="col-25">
                <label for="size"> Project Size:</label>
              </div>
              <div class="col-75">
                <select id="yardsize">
                  <option value="smaller200"> smaller than 200 sqrt</option>
                  <option value="200to500"> between 200 to 500 sqrt</option>
                  <option value="500to1000">between 500 to 1000 sqrt</option>
                  <option selected value="1000to2000">
                    between 1000 to 2000 sqrt
                  </option>
                  <option value="2000to5000">between 2000 to 5000 sqrt</option>
                  <option value="5000to9000">between 5000 to 9000 sqrt</option>
                  <option value="9000to15000">
                    between 9000 to 15000 sqrt
                  </option>
                  <option value="biger15000">larger than 15000 sqrt</option>
                </select>
              </div>

              <div class="row">
                <div class="col-25">
                  <label for="size">
                    {" "}
                    Want Expedite(within 2 weeks) Process:
                  </label>
                </div>
                <div class="col-75">
                  <select id="time">
                    <option value="4 weeks"> No Expedite</option>
                    <option value="2 week"> Expedite </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <input type="submit" value="      Click to Get Quote     " />
          </div>
        </form>
      </div>
    </div>
  );
}

function Examples() {
  return <div className="Examples">Examples coming soon!</div>;
}

export default App;
